





















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Pagination from "@/components/pagination/Pagination.vue";
import moment from "moment";
import {
  IOperationListQuery,
  IOperationLogModel,
  IOpUsers,
} from "../data/operationLog";
import { getOperationLogs, getAdminsInfo } from "@/remote/user";
import PopupUseInvitation from "@/components/PopupUseInvitation.vue";

// 装饰器模式
@Component({
  components: {
    Pagination,
    PopupUseInvitation,
  },
})
export default class extends Vue {
  operationPeople: string = "";
  // Data
  listLoading: boolean = false;
  list: IOperationLogModel[] = [];
  users: IOpUsers[] = [];
  options: Object[] = [
    {
      value: "create",
      label: "增加操作",
    },
    {
      value: "read",
      label: "查询操作",
    },
    {
      value: "update",
      label: "修改操作",
    },
    {
      value: "delete",
      label: "删除操作",
    },
    {
      value: "login",
      label: "登录操作",
    },
  ];
  totalSize = 0;

  listQuery: IOperationListQuery = {
    page: 1,
    size: 10,
    adminId: "",
    action: "",
    start: "",
    to: "",
  };
  timeRanges: string[] = ["", moment().format("YYYY-MM-DD")];

  // Methods
  mounted() {
    //  const res= await getAdminsInfo()
    getAdminsInfo().then((res) => {
      if (res && res.data && res.data.data) {
        this.users = res.data.data;
      }
      this.refreshTable();
      // console.log(this.users);
    });
  }

  datePickerOptions = {
    disabledDate(time: Date) {
      return time.getTime() > Date.now();
    },
  };

  //  已到店、未到店、已取消
  unlimitPickerOptions = {
    disabledDate(time: Date) {
      return (
        moment(time).isBefore(moment("2020-01-01")) ||
        moment(time).isAfter(moment().add(180, "d"))
      );
    },
  };
  public async queryData() {
    console.log(this.operationPeople)
    if (this.operationPeople) {
      const user = this.users.find((item) => {
        return  item.name  == this.operationPeople;
      });
      if(user){
        this.listQuery.adminId=user.id
      }else{
         this.$message.error("操作人不存在，请重试");
        this.list=[]
        this.totalSize=0
        return
      }
    } else  {
      this.listQuery.adminId = ''
    }
    this.listQuery.page = 1;
    this._fetchRservationRecords(this.listQuery);
  }
  public async refreshTable() {
    this._fetchRservationRecords(this.listQuery);
  }

  async _fetchRservationRecords(params: IOperationListQuery) {
    // console.log("_fetchRservationRecords");
    this.listLoading = true;
    try {
      let res = await getOperationLogs(params);

      // console.log(res);
      if (res && res.data && res.data.data) {
        const { items, total } = res.data.data;
        // console.log(items);
        this.totalSize = total;
        items.forEach((element: IOperationLogModel) => {
          element.createdAt = moment(element.createdAt).format(
            "YYYY-MM-DD HH:mm"
          );
          // console.log("this.adminId", element.adminId);
          // console.log("this.users", this.users);
          if (this.users && this.users.length > 0) {
            const filterUser = this.users.filter((user) => {
              return user.id == element.adminId;
            });
            // console.log("this.filterUser", filterUser);
            if (filterUser && filterUser.length > 0) {
              element.name = filterUser[0].name;
            }
          }
          if (this.options && this.options.length > 0) {
            const filterOption:any = this.options.filter((option:any) => {
              return option.value == element.action;
            });
            // console.log("this.filterUser", filterOption);
            if (filterOption && filterOption.length > 0) {
              element.actionName = filterOption[0].label;
            }
          }
          if(element.requestBody){
            element.requestBody=JSON.stringify(element.requestBody) 
          }else{
             element.requestBody='/'
          }
        });

        this.list = items;
        // console.log("ddkkkk");
        // console.log(this.list);
        this.listLoading = false;
      }
    } catch {
      if (params.page === 1) {
        this.list = [];
      }
      this.listLoading = false;
      // console.log("getReservationRecords 出错了");
    }
  }

  fetchUserSuggestion(queryString: string, callback: (names: any[]) => void) {
    if (queryString) {
      const regexp = new RegExp(queryString, 'i')
      const users = this.users.filter((user) => {
        return  regexp.test(user.name) ||  regexp.test(user.email);
      });  
      callback(users.map(item => ({key: item.id, value: item.name})))
    }
  }

 

  _resetTimeRange() {
    this.listQuery.start = "";
    this.listQuery.to = "";
  }

  _onChangeTimeRange(event: any) {
    console.log(event);
    if (event == null) {
      this.listQuery.start = "";
      this.listQuery.to = "";
    } else {
      this.listQuery.start = moment(event[0]).format("YYYY-MM-DD HH:mm:ss");
      this.listQuery.to = moment(event[1])
        .add(1, "day")
        .format("YYYY-MM-DD HH:mm:ss");
    }
    // this.listQuery.page = 1;
    // this.refreshTable();
  }

  // async _onClickDownload() {
  //   this.exportButtonLoading = true;
  //   try {
  //     const res = await downloadReservationRecords(this.listQuery);
  //     if (!res || !res.data) return;
  //     let a = document.createElement("a");
  //     let blob = new Blob([res.data], {
  //       type: "application/octet-stream", //这里放文件类型，后台返回的response会有这个文件类型，放进来就行
  //     });
  //     a.href = window.URL.createObjectURL(blob);
  //     a.download = "预约信息.xlsx";
  //     a.click();
  //     this.exportButtonLoading = false;
  //   } catch {
  //     this.exportButtonLoading = false;
  //     this.$message.error("导出失败，请重试");
  //     // console.log("exportCustomList 出错了");
  //   }
  // }
  _updatePage(value: any) {
     this.listQuery.page = value.page;
    this.refreshTable();
  }
}
