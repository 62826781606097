










interface IBookingCountModel {
  allCount: number;
}

import { Component, Vue, Watch } from "vue-property-decorator";
import OperationLogTable from "./components/OperationLogTable.vue"; 

import { ITabModel, IPanelData } from "@/data/commonModel";

import { UserModule } from "@/store/modules/user"; 
import { BookingTimerModule } from "@/store/modules/bookingTimer";
import { IBookingModel } from "@/pages/booking/data/bookingModel";

// 装饰器模式
@Component({
  components: { 
    OperationLogTable, 
  },
})
export default class extends Vue {
  // Data
  isPopupStoreManager: boolean = !!UserModule.wineries[0].roles.find(
    (role) => role.label === "Finance Team"
  );
  defaultActive: string = this.isPopupStoreManager ? "history" : "valid";
  panelObj: IPanelData = { type: this.defaultActive, searchText: "" ,show:true};
  editDialogVisible: boolean = false;
  editType: string = "new";
  bookingModel: IBookingModel | null = null;
  

  get switchToComingSoonTab() {
    return BookingTimerModule.switchToComingSoonTab;
  }

  // Watch
  @Watch("switchToComingSoonTab", {
    immediate: true,
  })
  onSwitchToComingSoonTabChange(value: boolean) {
    if (value) {
      this.defaultActive =
        this.defaultActive === "comingSoon2" ? "comingSoon" : "comingSoon2";
      // 用于防止当前type本身就是待确认时，watch方法不会执行， table无法刷新的情况
      this.panelObj.type =
        this.panelObj.type === "comingSoon" ? "comingSoon2" : "comingSoon";
      BookingTimerModule.RESETFLAG();
    } else if (this.defaultActive === "comingSoon") {
      this.defaultActive = "";
    }
  }


  _handleClickEdit(type: string, bookingModel: IBookingModel | null = null) {
    this.editDialogVisible = true;
    console.log("_handleClickEdit")
    this.editType = type;
    this.bookingModel = bookingModel;
  }

  _handleEditReservationSuccess() {
    // const ref: BookingTable = this.$refs["booking-table"] as BookingTable;
    // ref.refreshTable();
  }
}
